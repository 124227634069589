import React from 'react'
import LogoSimpleTv from '../../../Assets/LogoSimpleTv.png'
import HomeAfiliados from '../../Utils/HomeAfiliados';



function HomeScreen() {


    return (
        <div className='max-w-[1000px] 2xl:max-w-[1200px] mx-auto w-full flex flex-col gap-5 justify-center items-center h-min py-20 px-5'>
            <h2 className='md:text-5xl text-2xl text-[#140D57] font-bold text-center'>Ahora tu Deco Directv usalo en</h2>
            <img src={LogoSimpleTv} alt='Logo' className='md:w-[263px] w-[162px] object-cover' />
            <p className='md:text-xl text-base text-center md:w-80 w-60'>
                y obtén <span className='text-[#0072CE] md:text-xl text-base font-bold'>7 días de prueba gratis </span>
                para disfrutar tus programas favoritos.
            </p>
            <HomeAfiliados hiddenButton />

        </div>
    )
}

export default HomeScreen