import axios from 'axios';
import React, { createRef, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';

function HomeAfiliados({ hiddenButton }) {

    const { register, handleSubmit, watch, formState: { errors }, resetField } = useForm()
    const [afiliados, setAfilaidos] = useState(null);
    const [filteredAfiliados, setFilteredAfiliados] = useState(null);
    const [estados, setEstados] = useState(null);
    const [ciudades, setCiudades] = useState(null);
    const [data, setData] = useState({
        buttonOption: null,
        estado: '',
        selectedAfiliado: ''
    });
    const irForumalarioRef = createRef();


    const getAfiliados = async () => {
        try {
            const response = await axios('https://vb10e3v01k.execute-api.sa-east-1.amazonaws.com/production/afiliados')
            setAfilaidos(response.data);
            setFilteredAfiliados(response.data.responseAfiliados);

        } catch (error) {
            console.log(error);
        }
    }

    const cleanString = (string) => {
        return string.toLowerCase().replace(/\s+/g, '').normalize('NFD')
            .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
            .normalize();
    }


    const getEstados = () => {
        const estados = afiliados?.responseAfiliados.map(afiliado => afiliado.estado);
        const estadosUnicos = [...new Set(estados)];
        setEstados(estadosUnicos);

    }




    const onSelectEstado = () => {
        const activeRadio = document.querySelectorAll('.activeRadio');
        activeRadio.forEach(radio => {
            radio.classList.remove('activeRadio');
        })

        data.selectedAfiliado = '';

        resetField('ciudad');
        if (watch('estado') === 'Todos') {
            setFilteredAfiliados(afiliados?.responseAfiliados)
            const ciudades = afiliados?.responseAfiliados.map(afiliado => afiliado.ciudad);
            const ciudadesUnicas = [...new Set(ciudades)];
            setCiudades(ciudadesUnicas);
            return;
        };


        const afiliadosFiltrados = afiliados?.responseAfiliados.filter(afiliado => afiliado.estado === watch('estado'));
        setFilteredAfiliados(afiliadosFiltrados);
        const ciudades = afiliadosFiltrados?.map(afiliado => afiliado.ciudad);
        const ciudadesUnicas = [...new Set(ciudades)];
        setCiudades(ciudadesUnicas);
    }


    const onSelectCiudad = () => {
        if (watch('ciudad')) {
            const afiliadosFiltrados = afiliados?.responseAfiliados.filter(afiliado => afiliado.ciudad === watch('ciudad'));
            setFilteredAfiliados(afiliadosFiltrados);
        }
    }




    const ButtonOption = (e) => {
        setData({
            ...data,
            buttonOption: e.target.value
        })
        const activeButton = document.querySelectorAll('.btn');
        activeButton.forEach(button => {
            button.classList.remove('activeButton');
        })
        e.target.classList.add('activeButton');
    }




    useEffect(() => {
        getAfiliados();
    }, []);

    useEffect(() => {
        getEstados();
    }, [afiliados])

    useEffect(() => {
        if (watch('estado') !== '') {
            onSelectEstado();
        }

    }, [watch('estado')])

    useEffect(() => {
        onSelectCiudad();
    }, [watch('ciudad')])




    const onSelectAfiliado = (afiliado) => {
        setData({
            ...data,
            selectedAfiliado: afiliado
        })
        const activeRadio = document.querySelectorAll('.activeRadio');
        activeRadio.forEach(radio => {
            radio.classList.remove('activeRadio');
        })
        const radio = document.querySelector(`.Id-${afiliado.id}`);
        radio.classList.add('activeRadio');

        irForumalarioRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

    }

    return (
        <div className='w-full'>
            {
                hiddenButton &&
                <div className='flex flex-col items-center gap-5 mt-10'>
                    <p className='md:text-2xl text-sm text-[#140D57] font-bold text-center'>¿Cuentas con tu antena y
                        decodificador en buen estado?
                    </p>
                    <div className='flex gap-5 md:items-center justify-center w-full '>
                        <button onClick={ButtonOption} value={'Activacion'} className='bg-white text-[#9BA5B7] border-[#000000] border-2 px-10 py-2 rounded-3xl text-center md:text-xl btn w-[40%] md:w-min  '>Si</button>
                        <button onClick={ButtonOption} value={'Error Deco'} className='bg-white text-[#9BA5B7]  border-[#000000] border-2 px-10 py-2 rounded-3xl text-center md:text-xl btn w-[40%] md:w-min  '>No</button>
                    </div>
                </div>
            }
            <div className='flex flex-col md:flex-row gap-5 mt-10 w-full justify-between'>
                <div class="flex flex-col-reverse w-full lg:flex-row-reverse items-center gap-5">
                    <div ref={irForumalarioRef} class="grid md:h-20 md:flex-grow card md:w-[50%]  w-full mt-10 md:mt-0 rounded-box place-items-center">
                        {
                            data.selectedAfiliado == '' ?
                                <button disabled className='bg-white text-[#9BA5B7] btn border-[#000000] px-5 py-2 rounded-3xl text-center text-xl w-full disabled:'>Selecciona un afiliado</button>
                                :
                                <NavLink state={data.buttonOption ? { home: 1, typeform: data.buttonOption, estado: data.selectedAfiliado.estado, ciudad: data.selectedAfiliado.ciudad } : { home: 1, estado: data.selectedAfiliado.estado, ciudad: data.selectedAfiliado.ciudad }} to={data.selectedAfiliado.diff ? `/${cleanString(data.selectedAfiliado.estado)}/${cleanString(data.selectedAfiliado.ciudad)}/${cleanString(data.selectedAfiliado.url)}/${cleanString(data.selectedAfiliado.diff)}` : `/${cleanString(data.selectedAfiliado.estado)}/${cleanString(data.selectedAfiliado.ciudad)}/${cleanString(data.selectedAfiliado.url)}`} className=' bg-[#140D57] text-[#ffff] btn border-[#000000] px-5 py-2 rounded-3xl text-center text-xl w-full disabled:'>LLenar Formulario</NavLink>
                        }
                    </div>
                    <form className='w-[50%]'>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-10 md:h-20 flex-grow card rounded-box place-items-center'>
                            <select {...register('estado')} className='select select-primary  w-full'>
                                <option value="" selected disabled> Filtrar por Estado</option>
                                <option value="Todos">Todos</option>
                                {
                                    estados && estados.map(estado => (
                                        <option value={estado}>{estado}</option>
                                    ))
                                }
                            </select>

                            <select disabled={!watch('estado') || watch('estado') == 'Todos' ? true : false} {...register('ciudad')} className='select select-primary  w-full'>
                                <option value="" selected disabled> Filtrar por ciudad</option>
                                {
                                    ciudades && ciudades.map(ciudad => (
                                        <option value={ciudad}>{ciudad}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div className='flex flex-col gap-5 w-full mt-5'>
                {
                    filteredAfiliados && filteredAfiliados.sort(function (a, b) {
                        if (a.estado > b.estado) {
                            return 1;
                        }
                        if (a.estado < b.estado) {
                            return -1;
                        }
                        return 0;
                    }).map(afiliado => (
                        <>
                            <div className='form-control'>
                                <label className='cursor-pointer flex flex-row-reverse  md:flex-row gap-5 items-center justify-between md:justify-start w-full'>
                                    <div onClick={() => onSelectAfiliado(afiliado)}
                                        className={`w-5 h-5 rounded-full border-black border-2 Id-${afiliado.id}`}>
                                    </div>
                                    <div className='flex flex-col items-start w-72'>
                                        <span className='font-bold text-xl '>{afiliado.nombre_comercial}</span>
                                        <p className='text-[#9BA5B7] '>{afiliado.ciudad}, {afiliado.estado}</p>
                                        <p className='text-[#9BA5B7] text-sm '>{afiliado.direccion}</p>

                                    </div>
                                </label>
                            </div>
                            <div className='divider'></div>
                        </>
                    ))
                }
            </div>
        </div>
    )
}

export default HomeAfiliados