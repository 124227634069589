import React from 'react'
import Distribuidor from '../../../src/Assets/Distribuidor.png'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { useState } from 'react'
function Navbar({ img, activar, servicios, nosotros, ayuda, preguntas }) {

    const [isOpen, setIsOpen] = useState(false)

    const onClickNav = () => {
        setIsOpen(!isOpen)
    }

    const goToactivar = () => {
        activar.current?.scrollIntoView({ behavior: 'smooth' })
        setIsOpen(false)
    }

    const goToServicios = () => {
        servicios.current?.scrollIntoView({ behavior: 'smooth' })
        setIsOpen(false)
    }

    const goToNosotros = () => {
        nosotros.current?.scrollIntoView({ behavior: 'smooth' })
        setIsOpen(false)
    }

    const goToAyuda = () => {
        ayuda.current?.scrollIntoView({ behavior: 'smooth' })
        setIsOpen(false)
    }

    const goToPreguntas = () => {
        preguntas.current?.scrollIntoView({ behavior: 'smooth' })
        setIsOpen(false)
    }


    return (
        <section className='w-full 2xl:max-w-[1300px] max-w-[1000px] mx-auto px-3 md:px-0 '>
            <div className='text-[#000000] w-full flex justify-between items-end font-bold text-base'>
                {
                    img &&

                    <img src={img} alt='Banner' className='w-[130px] object-cover' />

                }
                <ul className='items-center gap-5 text-base hidden md:flex'>
                    <li onClick={goToactivar} className='cursor-pointer link link-hover text-base'>
                        <a>¿Como activar?</a>
                    </li>
                    <li onClick={goToServicios} className='cursor-pointer link link-hover text-base'>
                        <a>Servicios</a>
                    </li >
                    <li onClick={goToNosotros} className='cursor-pointer link link-hover text-base'>
                        <a>Nosotros</a>
                    </li >
                    <li onClick={goToAyuda} className='cursor-pointer link link-hover text-base'>
                        <a>Ayuda</a>
                    </li>
                    <li onClick={goToPreguntas} className='cursor-pointer link link-hover text-base'>
                        <a>Preguntas</a>
                    </li>
                </ul>
                <div>
                    <img src={Distribuidor} alt='Logo' className='w-[334px] object-cover md:ml-10 hidden md:block' />
                </div>

                <div className='md:hidden block z-50' onClick={onClickNav}>
                    {
                        !isOpen ?
                            <AiOutlineMenu size={40} /> : <AiOutlineClose size={40} />
                    }

                </div>
                <div className={isOpen ? 'fixed left-0 top-0 w-[100%] h-full bg-white p-4 ease-in-out duration-500' : 'fixed left-[-100%]   '}>
                    <img src={img} alt='Logo' className='w-[196px] object-cover md:ml-10 block md:hidden' />
                    <ul className='pt-24 uppercase'>
                        <li onClick={goToactivar} className='cursor-pointer link link-hover text-base font-bold p-4'>
                            <a>¿Como activar?</a>
                        </li>
                        <li onClick={goToServicios} className='cursor-pointer link link-hover text-base font-bold p-4'>
                            <a>Servicios</a>
                        </li >
                        <li onClick={goToNosotros} className='cursor-pointer link link-hover text-base font-bold p-4'>
                            <a>Nosotros</a>
                        </li >
                        <li onClick={goToAyuda} className='cursor-pointer link link-hover text-base font-bold p-4'>
                            <a>Ayuda</a>
                        </li>
                        <li onClick={goToPreguntas} className='cursor-pointer link link-hover text-base font-bold p-4'>
                            <a>Preguntas</a>
                        </li>
                    </ul>

                </div>
            </div>
        </section>
    )
}

export default Navbar