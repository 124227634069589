import React, { useEffect, useRef, useState } from 'react'
import Distribuidor from '../../../Assets/Distribuidor.png'
import LogoSimpleTv from '../../../Assets/LogoSimpleTv.png'
import Decodificador from '../../../Assets/Decodificadores 1.png'
import Asistencia from '../../../Assets/Asistencia.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Controller, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import CardCarousel from '../../Utils/CardCarousel'
import 'swiper/css/pagination';
import axios from 'axios'
import { NavLink, useLocation } from 'react-router-dom'
import CardPaso from '../../Utils/CardPaso'
import Formulario from '../../Utils/Formulario'
import Navbar from '../../Utils/Navbar'
import Elipse from '../../../Assets/Ellipse.png'
import ElipseMovil from '../../../Assets/ElipseMovil.png'
import DecodificarMovil from '../../../Assets/DecodificadorMobile.png'



function AfiliadosScreen({ id }) {
    const location = useLocation()
    const [mobile, setMobile] = useState(false)
    const [afiliado, setAfiliado] = useState(null)
    const [afiliadoColors, setAfiliadoColors] = useState('')
    const formularioRef = useRef(null);
    const activarRef = useRef();
    const serviciosRef = useRef();
    const nosotrosRef = useRef();
    const ayudaRef = useRef();
    const preguntasRef = useRef();
    const [formCreated, setFormCreated] = useState(false)

    const mobileDevice = () => {
        const width = window.innerWidth
        if (width <= 768) {
            setMobile(true)
        }
    }

    const goToForm = () => {
        formularioRef.current?.scrollIntoView({ behavior: 'smooth' })
    }


    const afiliadoInfo = async () => {

        const response = await axios(`https://vb10e3v01k.execute-api.sa-east-1.amazonaws.com/production/afiliados/${id}`);
        setAfiliado(response.data.responseAfiliado);
        if (response.data.responseAfiliado?.content) {
            setAfiliadoColors({
                bgColorPrimary: `${response.data.responseAfiliado?.content.primary_color}`,
                textColorPrimary: `${response.data.responseAfiliado?.content.primary_color}`,
                bgColorSecondary: `${response.data.responseAfiliado?.content.secondary_color}`,
                textColorSecondary: `${response.data.responseAfiliado?.content.secondary_color}`
            })
        } else {
            setAfiliadoColors({})
        }
    }
    useEffect(() => {
        afiliadoInfo()
    }, [])

    useEffect(() => {
        mobileDevice()
    }, [])

    useEffect(() => {
        if (formCreated && location.state?.home) {
            formularioRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [formCreated])






    return (
        <div className='w-full bg-[#FFFF] '>
            {
                afiliado &&
                <>
                    <div className='w-full h-min pt-2'>
                        <Navbar img={afiliado?.banner} activar={activarRef} servicios={serviciosRef} nosotros={nosotrosRef} ayuda={ayudaRef} preguntas={preguntasRef} />
                        <section className='w-full  h-min  text-center gap-7 md:py-24 pt-12  md:px-0 '>
                            <div className='flex 2xl:max-w-[1300px] max-w-[1000px] mx-auto  flex-col items-center justify-center gap-5'>
                                <h2 style={{ color: afiliadoColors?.textColorSecondary ? afiliadoColors?.textColorSecondary : '#140D57' }} className='md:text-5xl text-xl font-bold'>Ahora tu Deco Directv usalo en</h2>
                                <img src={LogoSimpleTv} alt='Logo' className='md:w-[263px] w-[162px] object-cover' />
                                <p className='md:text-2xl text-sm font-normal'>y obtén <span className='text-[#0072CE] font-bold'>7 dias</span> <br></br>
                                    de prueba gratis
                                </p>
                                <a onClick={goToForm} className='btn text-white rounded-xl px-12 text-base font-bold' style={{ backgroundColor: afiliadoColors?.bgColorPrimary ? afiliadoColors?.bgColorPrimary : '#140D57' }}>
                                    Quiero mi descuento
                                </a>
                                {/* <a className='btn text-white rounded-xl px-8 text-base font-bold bg-transparent' style={{ borderColor: afiliadoColors?.bgColorPrimary ? afiliadoColors?.bgColorPrimary : '#140D57', color: afiliadoColors?.bgColorPrimary ? afiliadoColors?.bgColorPrimary : '#0000' }}>
                                    Decos compatibles
                                </a> */}
                                <img src={Decodificador} alt='Afiliados' className=' w-full object-cover hidden md:block' />
                            </div>
                            <img src={DecodificarMovil} alt='Afiliados' className=' w-full object-cover md:hidden block mt-10' />
                        </section>
                        <section ref={activarRef} style={{ backgroundColor: '#140D57' }} className='h-min py-5  w-full overflow-hidden '>
                            <div className='2xl:max-w-[1300px] md:max-w-[1100px] w-full mx-auto flex flex-col items-center relative justify-center  text-center  gap-10  z-20 md:py-24 py-12'>
                                <div className='text-center flex flex-col justify-center items-center p-4 md:px-0'>
                                    <h3 className='text-white md:text-5xl text-xl font-bold leading-loose md:leading-normal'>Paso a paso de como<br /> activar tu decodificador</h3>
                                    <p className='text-white text-sm mt-5 md:text-xl md:w-[40%]'>Descubre como activar tu decodificador Directv y comenzar a disfrutar de todo lo que te ofrece Simpletv.</p>
                                </div>
                                <div className='grid md:grid-rows-3 md:grid-cols-3 grid-cols-1 gap-10 p-4 overflow-hidden py-10'>
                                    <div className='md:row-span-2 '>
                                        <CardPaso number={1} text={'Regístrate en Mi Simple con los datos del decodificador que asignarás como principal. Necesitas ID y el Número de tarjeta de acceso.'} />
                                    </div>
                                    <div>
                                        <CardPaso number={2} text={'Enciende los decos.'} />
                                    </div>
                                    <div >
                                        <CardPaso number={3} text={'Reinstala tus equipos, si están desconectados.'} />
                                    </div>
                                    <div className='md:col-span-2'>
                                        <CardPaso number={4} text={'Paga la tarifa única de afiliación por cada deco activado, Verás este monto junto al precio del Plan, de los Paquetes Premium y los decos adicionales.'} minsize={true} />
                                    </div>
                                    <div className='md:col-span-3'>
                                        <CardPaso number={5} text={'Recarga el saldo necesario para disfrutar de tu programación y de inmediato estarás conectado a la señal Simpletv.'} />
                                    </div>

                                </div>
                                {/* <div className='flex flex-col self-start'>
                                    <p className='text-white font-bold text-5xl'>¡Activarlo es muy fácil!</p>
                                    <p className='text-white text-lg'>Reproduce nuestro video y descubre como usar tu Deco. </p>
                                </div> */}
                                <img src={Elipse} alt='Video' className='absolute hidden md:block m-auto left-0 right-0 -z-10 ' />
                                <img src={ElipseMovil} alt='Video' className='absolute block md:hidden m-auto left-0 right-0 -z-10 w-full  top-0' />
                            </div>
                        </section>
                        <section ref={serviciosRef} className='w-full 2xl:max-w-[1300px] max-w-[1100px] mx-auto flex flex-col text-center md:py-14 h-min p-4'>
                            <div className='flex flex-col items-center justify-center'>
                                <div className='md:my-20 my-10 flex flex-col items-center justify-center'>
                                    <h4 style={{ color: afiliadoColors?.textColorSecondary ? afiliadoColors?.textColorSecondary : '#140D57' }} className='md:text-5xl text-xl font-bold'>Nuestros servicios</h4>
                                    <p style={{ color: afiliadoColors?.textColorSecondary ? afiliadoColors?.textColorSecondary : '#140D57' }} className='md:text-lg text-sm font-normal md:w-[60%]'>Ofrecemos distintos servicios especializados para que puedas llevarte la mejor experiencia posible.</p>
                                </div>
                                <div className='w-full md:flex hidden h-[520px]'>
                                    <Swiper
                                        modules={[Controller, Pagination]}
                                        slidesPerView={3}
                                        spaceBetween={20}
                                        pagination

                                    >
                                        <SwiperSlide>
                                            <CardCarousel servicio={true} colors={afiliadoColors} />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <CardCarousel deco={true} colors={afiliadoColors} />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <CardCarousel recarga={true} colors={afiliadoColors} />
                                        </SwiperSlide>

                                    </Swiper>
                                </div>
                                <div className='w-full md:hidden h-[600px] block'>
                                    <Swiper
                                        modules={[Controller, Navigation, Autoplay, Pagination]}
                                        slidesPerView={1}
                                        spaceBetween={20}
                                        autoplay
                                        pagination

                                        className='h-full'
                                    >
                                        <SwiperSlide >
                                            <CardCarousel servicio={true} colors={afiliadoColors} />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <CardCarousel deco={true} colors={afiliadoColors} />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <CardCarousel recarga={true} colors={afiliadoColors} />
                                        </SwiperSlide>

                                    </Swiper>
                                </div>
                            </div>
                        </section>
                        <section className='w-full 2xl:max-w-[1200px] max-w-[1100px] mx-auto flex flex-col h-min py-10 px-3 md:px-3'>
                            <div className={`bg-[#FAFAFA]  rounded-2xl flex md:flex-row flex-col-reverse justify-between gap-5 w-full shadow-xl`}>
                                <div className='flex justify-evenly items-start flex-col gap-5  text-center md:text-start p-10 md:px-20 md:py-24'>
                                    <h2 style={{ color: afiliadoColors?.textColorSecondary ? afiliadoColors?.textColorSecondary : '#140D57' }} className='text-[32px] md:text-5xl font-bold leading-[1.5]'>Activa tu deco
                                        y obtén <span style={{ color: afiliadoColors?.textColorPrimary ? afiliadoColors?.textColorPrimary : 'black' }} className={`font-bold text-[#0072CE]`}>7 días de prueba gratis.</span>
                                    </h2>
                                </div>
                                <div style={{ backgroundColor: afiliadoColors?.bgColorSecondary ? afiliadoColors?.bgColorSecondary : '#140D57' }} className='w-full rounded-t-3xl md:rounded-r-3xl md:rounded-tl-none h-[200px] md:h-[350px]'></div>
                            </div>

                        </section>
                        <section ref={formularioRef} className='w-full 2xl:max-w-[1300px] max-w-[1100px] mx-auto flex flex-col md:gap-5 text-center justify-center items-center py-10 h-min p-3 md:px-0 formulario'>
                            <div className='flex flex-col gap-10 items-center my-10'>
                                <h4 style={{ color: afiliadoColors?.textColorSecondary ? afiliadoColors?.textColorSecondary : '#140D57' }} className='font-bold text-xl leading-loose md:text-5xl md:leading-tight'>¡Estás a un paso de ver los<br /><span style={{ color: afiliadoColors?.textColorPrimary ? afiliadoColors?.textColorPrimary : 'black' }} className=''>mejores programas de TV!</span></h4>
                                <p className='md:text-2xl text-sm md:w-[50%] w-[80%]' style={{ color: afiliadoColors?.textColorSecondary ? afiliadoColors?.textColorSecondary : '#140D57' }}>Completa nuestro formulario para recibir toda la información que necesitas.</p>
                            </div>
                            <Formulario afiliado={afiliado} afialiadoColors={afiliadoColors} homeVisit={location.state?.typeform} homeCiudad={location.state?.ciudad} homeEstado={location.state?.estado} setFormCreated={setFormCreated} />
                        </section>
                        <section ref={nosotrosRef} className='w-full 2xl:max-w-[1300px] max-w-[1000px] mx-auto flex items-center justify-center h-min py-10 px-3 md:px-0'>
                            <div className='flex flex-col-reverse md:flex-row justify-center items-center gap-10 w-full'>
                                <div className='md:w-[600px] md:h-[490px]' >
                                    {
                                        afiliado.images?.images2 &&
                                        <img className='object-cover h-full w-full rounded-2xl' src={afiliado.images?.images2} alt="logo" />
                                    }
                                </div>
                                <div className='flex flex-col items-center justify-center text-center md:text-left md:items-start gap-6 md:w-[45%] p-10'>
                                    <img src={afiliado?.banner} alt="logo" className='w-[200px]' />
                                    <p style={{ color: afiliadoColors?.textColorSecondary ? afiliadoColors?.textColorSecondary : '#140D57' }} className='text-xl md:text-3xl 2xl:text-5xl font-bold'>No dudes en visitarnos para mayor ayuda</p>
                                    <p className='text-sm md:text-2xl text-black'>{`Visita nuestra tienda en ${afiliado.direccion}`}</p>
                                </div>
                            </div>

                        </section>
                        <section ref={ayudaRef} style={{ backgroundColor: `${afiliado?.content ? afiliadoColors.bgColorSecondary : '#140D57'}` }} className={` w-full h-min  mt-10 flex justify-center items-center py-10 md:py-10 shadow-2xl`}>
                            <div className='2xl:max-w-[1300px] md:max-w-[1000px] lg:max-w-[1000px] mx-auto flex flex-col justify-between items-center w-full gap-10 p-4'>
                                <div className='bg-[#DAEFFF] bg-bg-asistencia bg-cover bg-no-repeat flex flex-col md:flex-row 2xl:justify-between md:justify-around items-center p-10 2xl:p-20 md:p-10 w-full rounded-2xl gap-5 md:gap-0'>
                                    <div className='flex flex-col justify-center items-center md:items-start md:w-[29rem] gap-5'>
                                        <h2 className='text-xl md:text-6xl font-bold text-center md:text-start '>¿Necesitas que te ayudemos?</h2>
                                        <p className='text-sm md:text-xl font-normal text-center md:text-start'>Preguntanos todo lo que necesites para poder resolver tus dudas en tu proceso de activación de tu Deco.</p>
                                        <a onClick={goToForm} style={{ backgroundColor: `${afiliado?.content ? afiliadoColors.bgColorPrimary : '#140D57'}` }} className={`btn rounded-xl text-white font-bold text-base`}> Obtener ayuda</a>
                                    </div>
                                    <div>
                                        <img src={Asistencia} alt='Asistencia' className='2xl:w-[400px] 2xl:h-[400px] md:w-[300px] md:h-[300px] w-[300px] h-[300px] object-contain' />
                                    </div>
                                </div>
                                <div className='text-white w-full flex flex-col md:flex-row justify-between  items-center text-center md:text-left gap-10'>
                                    <h3 className='text-xl md:text-4xl 2xl:text-6xl font-bold 2xl:leading-[1.2] w-[60%]'>
                                        Agenda tu
                                        instalación
                                        con nosotros
                                    </h3>
                                    <p className='text-sm md:text-xl font-normal'>
                                        Contáctanos y elige una fecha y hora conveniente para ti. ¡Haremos que todo esté listo para que disfrutes al máximo de tu televisión!
                                    </p>
                                    <a onClick={goToForm} style={{ backgroundColor: `${afiliado?.content ? afiliadoColors.bgColorPrimary : 'black'}` }} className={`btn rounded-xl text-white  font-bold text-base self-center border-0`}> Agenda visita con nosotros</a>
                                </div>
                            </div>
                        </section>
                        <section ref={preguntasRef} className='w-full mx-auto h-min py-24 flex flex-col bg-white'>
                            <div className='w-full 2xl:max-w-[1300px] max-w-[1000px] mx-auto p-3'>
                                <h3 style={{ color: afiliadoColors?.textColorSecondary ? afiliadoColors?.textColorSecondary : '#140D57' }} className='text-3xl md:text-5xl font-bold text-center md:text-left'>Preguntas Frecuentes</h3>
                                <div className='flex w-full flex-col gap-5 mt-10 '>
                                    {
                                        afiliado.descripcion &&
                                        <div className="collapse collapse-arrow bg-[#FFFFFF]  border border-[#D9D9D6] rounded-2xl ">
                                            <input type="radio" name="my-accordion-2" />
                                            <div className="collapse-title text-xl font-bold">
                                                ¿Qué servicios ofrecen?
                                            </div>
                                            <div className="collapse-content">
                                                <p>{afiliado.descripcion}</p>
                                            </div>
                                        </div>
                                    }
                                    {/* <div className="collapse collapse-arrow bg-[#FFFFFF]  border border-[#D9D9D6] rounded-2xl px-2">
                                        <input type="radio" name="my-accordion-2" />
                                        <div className="collapse-title text-xl font-bold">
                                            ¿Cuáles son los decos
                                            compatibles con Simpletv?
                                        </div>
                                        <div className="collapse-content">
                                            <p>hello</p>
                                        </div>
                                    </div> */}
                                    {
                                        afiliado.direccion &&
                                        <div className="collapse collapse-arrow bg-[#FFFFFF]  border border-[#D9D9D6] rounded-2xl px-2 ">
                                            <input type="radio" name="my-accordion-2" />
                                            <div className="collapse-title text-xl font-bold">
                                                ¿Dónde está ubicada
                                                la tienda?
                                            </div>
                                            <div className="collapse-content">
                                                <p>
                                                    La tienda se encuentra en <br></br>
                                                    {afiliado.direccion}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        afiliado.horario &&
                                        <div className="collapse collapse-arrow bg-[#FFFFFF]  border border-[#D9D9D6] rounded-2xl px-2 ">
                                            <input type="radio" name="my-accordion-2" />
                                            <div className="collapse-title text-xl font-bold">
                                                ¿Cuál es el horario
                                                de atención?
                                            </div>
                                            <div className="collapse-content">
                                                <p>
                                                    El horario de atenciòn es de <br></br>
                                                    {afiliado.horario}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </section>
                        <section className='w-full mx-auto h-min py-14 bg-white'>
                            <div className='w-full max-w-[1300px] mx-auto flex flex-col gap-10 items-center justify-center'>
                                {
                                    afiliado?.banner ? <img src={afiliado?.banner} alt='Afiliado' className='w-[200px] h-[100px] object-contain' /> : null
                                }
                                <h3 className='text-xl md:text-6xl text-center font-bold' style={{ color: afiliadoColors?.textColorSecondary ? afiliadoColors?.textColorSecondary : '#140D57' }}>Disfruta todos tus<br></br><span style={{ color: afiliadoColors?.textColorPrimary ? afiliadoColors?.textColorPrimary : 'black' }} className=''>shows favoritos</span></h3>
                                <button onClick={goToForm} style={{ backgroundColor: `${afiliado?.content ? afiliadoColors.bgColorPrimary : '#0072CE'}` }} className='btn rounded-xl text-white  font-bold text-base'>Activar mi deco</button>
                                <div className='max-w-[1000px] w-full grid grid-cols-1 md:grid-cols-3 gap-10 mt-10'>
                                    {
                                        afiliado.direccion &&
                                        <div className='w-full flex flex-col gap-5 text-center '>
                                            <h3 className='text-base font-bold'>Local comercial dirección</h3>
                                            <p className='text-base'>{afiliado.direccion}</p>
                                        </div>
                                    }
                                    {
                                        afiliado.horario &&
                                        <div className='w-full flex flex-col gap-5 text-center '>
                                            <h3 className='text-base font-bold'>Horario de atención</h3>
                                            <p className='text-base'>{afiliado.horario}</p>
                                        </div>
                                    }
                                    {
                                        afiliado.telefono &&
                                        <div className='w-full flex flex-col gap-5 text-center '>
                                            <h3 className='text-base font-bold'>Numero de contacto</h3>
                                            <p className='text-base'>{afiliado.telefono}</p>
                                        </div>
                                    }
                                </div>
                                <img src={Distribuidor} alt='Logo' className='w-[300px] md:w-[500px] h-[100px] object-contain' />

                                <NavLink to="/politicas-de-privacidad">Políticas de Privacidad y Protección de Datos Personales</NavLink>

                            </div>
                        </section>
                    </div>
                </>
            }
        </div >
    )
}

export default AfiliadosScreen