import React from 'react'

function CardPaso({ number, text, minsize }) {
    return (
        <div className='bg-[#140D57] text-white py-10 px-4 gap-5 relative rounded-2xl h-full  flex  items-center shadow-2xl'>
            <div className='rounded-full border border-white w-min px-5 py-2'>
                <h1 className='text-5xl  font-bold text-center'>{number}</h1>
            </div>
            {
                minsize ?
                    <p className='text-left text-xl md:text-3xl w-[240px] md:w-full '>{text}</p>
                    :
                    <p className='text-left text-xl md:text-3xl w-[240px] md:w-full'>{text}</p>
            }


        </div>
    )
}

export default CardPaso