import { BrowserRouter, Route, Routes } from "react-router-dom";

import axios from "axios";
import { useEffect, useState } from "react";
import AfiliadosScreen from "./Components/Screen/AfiliadosScreen/index.js";
import HomeScreen from "./Components/Screen/HomeScreen/index.js";
import FormularioScreen from "./Components/Screen/FormularioScreen/index.js";
import PoliticasPrivacidadScreen from "./Components/Screen/PoliticasScreen/index.js";
import DecoScreen from "./Components/Screen/DecoScreen/index.js";

function App() {

  const [region, setRegions] = useState(null);
  const [url, setUrl] = useState(null);

  const getRegions = async () => {
    const response = await axios('https://vb10e3v01k.execute-api.sa-east-1.amazonaws.com/production/afiliados/regions');
    setRegions(response.data.responseAfiliados);

  };


  const createArrayWithurl = async () => {
    const array = [];
    const response = region.map((ciudad) => {
      ciudad.afiliados.map((afiliado) => {
        if (afiliado.diff) {
          array.push({
            url: `/${cleanString(ciudad.name)}/${cleanString(afiliado.ciudad)}/${cleanString(afiliado.url)}/${cleanString(afiliado.diff)}`,
            id: afiliado.id,
          })
        } else if (afiliado.ciudad === null) {
          array.push({
            url: `/${cleanString(ciudad.name)}/${cleanString(afiliado.url)}`,
            id: afiliado.id,
          })
        } else {
          array.push({
            url: `/${cleanString(ciudad.name)}/${cleanString(afiliado.ciudad)}/${cleanString(afiliado.url)}`,
            id: afiliado.id,
          })
        }
      })
    })
    setUrl(array);

  }


  const cleanString = (string) => {
    return string.toLowerCase().replace(/\s+/g, '').normalize('NFD')
      .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
      .normalize();
  }


  useEffect(() => {
    getRegions();
  }, []);

  useEffect(() => {
    if (region !== null) {
      createArrayWithurl();
    }
  }, [region]);




  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/decodificador-LH01' element={<DecoScreen />} />
        <Route path='/formulario' element={<FormularioScreen />} />
        <Route path="/politicas-de-privacidad" element={<PoliticasPrivacidadScreen />} />
        {
          url && url.map((path, index) => {
            return <Route path={path.url} element={<AfiliadosScreen id={path.id} />} />
          })
        }

      </Routes>
    </BrowserRouter>
  );
}

export default App;
