import React, { useEffect } from 'react'
import Formulario from '../../Utils/Formulario'
import { useLocation } from 'react-router-dom'


function FormularioScreen() {

  const scrollTop = () => {
    window.scrollTo(0, 0, { behavior: 'smooth' })
  }

  useEffect(() => {
    scrollTop()
  }, [])

  const location = useLocation()
  return (
    <div className='w-full 2xl:max-w-[1200px] max-w-[1000px] mx-auto flex flex-col gap-10 justify-center items-center h-min py-10 p-5 md:px-0'>
      <div className='w-full bg-[#DAEFFF] md:px-28 md:py-24 p-10 rounded-2xl'>
        {
          location.state.type == 'dcto' &&
          <h1 className='md:text-6xl text-4xl font-bold'>
            <span className='text-6xl'>
              Activa tu deco Directv con Simple TV<br></br>
            </span>
            ¡Envía el formulario y disfruta de tu <span className='text-[#0072CE]'>20% de descuento!</span></h1>
        }
        {
          location.state.type == 'instalacion' &&
          <h1 className='md:text-6xl text-4xl font-bold'>¡Estás a un paso de ver los <span className='text-[#0072CE]'>mejores programas de TV!</span></h1>
        }
        {
          location.state.type == 'ayuda' &&
          <h1 className='md:text-6xl text-4xl font-bold'>¡Te ayudamos a revolver <span className='text-[#0072CE]'>todos tus problemas!</span></h1>
        }
        {
          location.state.type == 'activar' &&
          <h1 className='md:text-6xl text-4xl font-bold'>¡Activa tu Deco y <span className='text-[#0072CE]'>comienza a disfrutar!</span></h1>
        }
      </div>
      <div className='w-full'>
        <Formulario typeForm={location.state.type} afiliado={location.state.afiliado} />

      </div>


    </div >
  )
}

export default FormularioScreen